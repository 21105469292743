.selected-tickets-list-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  thead
  tr
  th {
  border: none !important;
  background-color: #ffffff !important;
}

.selected-tickets-list-wrapper
  .Polaris-IndexTable__Table.Polaris-IndexTable__Table--unselectable.Polaris-IndexTable__Table--sticky
  .Polaris-Text--root.Polaris-Text--bodySm.Polaris-Text--medium {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.selected-tickets-list-wrapper .Polaris-IndexTable__TableCell:first-child {
  width: 62.5%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2VkaXQtb3JkZXIvZXZlbnRzLWFuZC1wcm9kdWN0cy1hZGQtY2FyZC9zdHlsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7Ozs7O0VBS0UsdUJBQXVCO0VBT3ZCLG9DQUFvQztBQU50Qzs7QUFTQTs7O0VBR0UsV0FBVztFQUNYLGdCQUFnQjtFQUNoQixlQUFlO0FBQ2pCOztBQUVBO0VBQ0UsWUFBWTtBQUNkIiwiZmlsZSI6InNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2VkaXQtb3JkZXIvZXZlbnRzLWFuZC1wcm9kdWN0cy1hZGQtY2FyZC9zdHlsZS5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc2VsZWN0ZWQtdGlja2V0cy1saXN0LXdyYXBwZXJcbiAgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tdW5zZWxlY3RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXN0aWNreVxuICB0aGVhZFxuICB0clxuICB0aCB7XG4gIGJvcmRlcjogbm9uZSAhaW1wb3J0YW50O1xufVxuLnNlbGVjdGVkLXRpY2tldHMtbGlzdC13cmFwcGVyXG4gIC5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGUtLXVuc2VsZWN0YWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS1zdGlja3lcbiAgdGhlYWRcbiAgdHJcbiAgdGgge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmZmZmICFpbXBvcnRhbnQ7XG59XG5cbi5zZWxlY3RlZC10aWNrZXRzLWxpc3Qtd3JhcHBlclxuICAuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS5Qb2xhcmlzLUluZGV4VGFibGVfX1RhYmxlLS11bnNlbGVjdGFibGUuUG9sYXJpcy1JbmRleFRhYmxlX19UYWJsZS0tc3RpY2t5XG4gIC5Qb2xhcmlzLVRleHQtLXJvb3QuUG9sYXJpcy1UZXh0LS1ib2R5U20uUG9sYXJpcy1UZXh0LS1tZWRpdW0ge1xuICBjb2xvcjogIzAwMDtcbiAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgZm9udC1zaXplOiAxM3B4O1xufVxuXG4uc2VsZWN0ZWQtdGlja2V0cy1saXN0LXdyYXBwZXIgLlBvbGFyaXMtSW5kZXhUYWJsZV9fVGFibGVDZWxsOmZpcnN0LWNoaWxkIHtcbiAgd2lkdGg6IDYyLjUlO1xufVxuIl19 */