.quillWrapper {
  border: 1px solid #8a8a8a61;
  border-radius: 10px;
  overflow: hidden;
}

.quillWrapper :global(.ql-container) {
  border: none !important;
}

.quillWrapper :global(.ql-editor) {
  padding: 12px;
} 
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL3RleHQtc2VjdGlvbi9zdHlsZXMubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDJCQUEyQjtFQUMzQixtQkFBbUI7RUFDbkIsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0UsYUFBYTtBQUNmIiwiZmlsZSI6InNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL3RleHQtc2VjdGlvbi9zdHlsZXMubW9kdWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5xdWlsbFdyYXBwZXIge1xuICBib3JkZXI6IDFweCBzb2xpZCAjOGE4YThhNjE7XG4gIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gIG92ZXJmbG93OiBoaWRkZW47XG59XG5cbi5xdWlsbFdyYXBwZXIgOmdsb2JhbCgucWwtY29udGFpbmVyKSB7XG4gIGJvcmRlcjogbm9uZSAhaW1wb3J0YW50O1xufVxuXG4ucXVpbGxXcmFwcGVyIDpnbG9iYWwoLnFsLWVkaXRvcikge1xuICBwYWRkaW5nOiAxMnB4O1xufSAiXX0= */