.quantity-field .Polaris-TextField {
  width: 100px;
}

.full-width-divider {
  width: 120%;
  margin: 0 -16px;
}

.custom-bg-info {
  background-color: #ecf5fe;
}

.custom-font-bold {
  font-weight: 550;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL3JlZnVuZC1vcmRlci9zdHlsZS5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxZQUFZO0FBQ2Q7O0FBRUE7RUFDRSxXQUFXO0VBQ1gsZUFBZTtBQUNqQjs7QUFFQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFFQTtFQUNFLGdCQUFnQjtBQUNsQiIsImZpbGUiOiJzcmMvZmVhdHVyZS9vcmRlcnMvY29tcG9uZW50cy9yZWZ1bmQtb3JkZXIvc3R5bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnF1YW50aXR5LWZpZWxkIC5Qb2xhcmlzLVRleHRGaWVsZCB7XG4gIHdpZHRoOiAxMDBweDtcbn1cblxuLmZ1bGwtd2lkdGgtZGl2aWRlciB7XG4gIHdpZHRoOiAxMjAlO1xuICBtYXJnaW46IDAgLTE2cHg7XG59XG5cbi5jdXN0b20tYmctaW5mbyB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNlY2Y1ZmU7XG59XG5cbi5jdXN0b20tZm9udC1ib2xkIHtcbiAgZm9udC13ZWlnaHQ6IDU1MDtcbn1cbiJdfQ== */