.event-overview-tickets-info-card .Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignTop.Polaris-DataTable__Cell--header {
  background-color: #fff;
}

.event-overview-tickets-info-card .Polaris-DataTable__TableRow.Polaris-DataTable--hoverable:last-child {
  background-color: #F7F7F7;
}

.event-overview-tickets-info-card .Polaris-DataTable {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50LW92ZXJ2aWV3L3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxzQkFBc0I7QUFDeEI7O0FBRUE7RUFDRSx5QkFBeUI7QUFDM0I7O0FBRUE7RUFDRSw4Q0FBOEM7QUFDaEQiLCJmaWxlIjoic3JjL2ZlYXR1cmUvZXZlbnRzL2NvbXBvbmVudHMvZXZlbnQtb3ZlcnZpZXcvc3R5bGVzLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5ldmVudC1vdmVydmlldy10aWNrZXRzLWluZm8tY2FyZCAuUG9sYXJpcy1EYXRhVGFibGVfX0NlbGwuUG9sYXJpcy1EYXRhVGFibGVfX0NlbGwtLXZlcnRpY2FsQWxpZ25Ub3AuUG9sYXJpcy1EYXRhVGFibGVfX0NlbGwtLWhlYWRlciB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmY7XG59XG5cbi5ldmVudC1vdmVydmlldy10aWNrZXRzLWluZm8tY2FyZCAuUG9sYXJpcy1EYXRhVGFibGVfX1RhYmxlUm93LlBvbGFyaXMtRGF0YVRhYmxlLS1ob3ZlcmFibGU6bGFzdC1jaGlsZCB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNGN0Y3Rjc7XG59XG5cbi5ldmVudC1vdmVydmlldy10aWNrZXRzLWluZm8tY2FyZCAuUG9sYXJpcy1EYXRhVGFibGUge1xuICBib3gtc2hhZG93OiAwcHggMXB4IDJweCAwcHggcmdiYSgwLCAwLCAwLCAwLjIpO1xufVxuIl19 */