.payment-table {
  width: 100%;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  overflow: hidden;
}

.payment-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 5px 16px;
}

.payment-table-row:first-child {
  padding-top: 13px;
}
.payment-table-row:last-child {
  border-top: 1px solid #f1f1f1;
  padding-bottom: 15px;
  padding-top: 15px;
}

.payment-table-cell {
  display: flex;
  align-items: center;
}

.payment-table-cell.text-center {
  justify-content: center;
}

.payment-table-cell.text-right {
  justify-content: flex-end;
} 
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2NvbW1vbi9wYXltZW50LXRhYmxlL3N0eWxlcy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxXQUFXO0VBQ1gseUJBQXlCO0VBQ3pCLGtCQUFrQjtFQUNsQixnQkFBZ0I7QUFDbEI7O0FBRUE7RUFDRSxhQUFhO0VBQ2Isa0NBQWtDO0VBQ2xDLGlCQUFpQjtBQUNuQjs7QUFFQTtFQUNFLGlCQUFpQjtBQUNuQjtBQUNBO0VBQ0UsNkJBQTZCO0VBQzdCLG9CQUFvQjtFQUNwQixpQkFBaUI7QUFDbkI7O0FBRUE7RUFDRSxhQUFhO0VBQ2IsbUJBQW1CO0FBQ3JCOztBQUVBO0VBQ0UsdUJBQXVCO0FBQ3pCOztBQUVBO0VBQ0UseUJBQXlCO0FBQzNCIiwiZmlsZSI6InNyYy9mZWF0dXJlL29yZGVycy9jb21wb25lbnRzL2NvbW1vbi9wYXltZW50LXRhYmxlL3N0eWxlcy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucGF5bWVudC10YWJsZSB7XG4gIHdpZHRoOiAxMDAlO1xuICBib3JkZXI6IDFweCBzb2xpZCAjZjFmMWYxO1xuICBib3JkZXItcmFkaXVzOiA4cHg7XG4gIG92ZXJmbG93OiBoaWRkZW47XG59XG5cbi5wYXltZW50LXRhYmxlLXJvdyB7XG4gIGRpc3BsYXk6IGdyaWQ7XG4gIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmciAxZnI7XG4gIHBhZGRpbmc6IDVweCAxNnB4O1xufVxuXG4ucGF5bWVudC10YWJsZS1yb3c6Zmlyc3QtY2hpbGQge1xuICBwYWRkaW5nLXRvcDogMTNweDtcbn1cbi5wYXltZW50LXRhYmxlLXJvdzpsYXN0LWNoaWxkIHtcbiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkICNmMWYxZjE7XG4gIHBhZGRpbmctYm90dG9tOiAxNXB4O1xuICBwYWRkaW5nLXRvcDogMTVweDtcbn1cblxuLnBheW1lbnQtdGFibGUtY2VsbCB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5wYXltZW50LXRhYmxlLWNlbGwudGV4dC1jZW50ZXIge1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbn1cblxuLnBheW1lbnQtdGFibGUtY2VsbC50ZXh0LXJpZ2h0IHtcbiAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDtcbn0gIl19 */