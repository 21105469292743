.general-admission-table thead tr{
    padding: 0 30px !important;
}

.general-admission-table tbody tr{
    padding: 0 30px !important;
}

.general-admission-table th,
.general-admission-table td {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.vendor-table thead tr{
    padding: 0 30px !important;
}

.vendor-table tbody tr{
    padding: 0 30px !important;
}

.vendor-table th,
.vendor-table td {
    padding-left: 30px !important;
    padding-right: 30px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlL2V2ZW50cy9jb21wb25lbnRzL2V2ZW50LXRpY2tldHMtc29sZC90YWJsZXMvc3R5bGUuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksMEJBQTBCO0FBQzlCOztBQUVBO0lBQ0ksMEJBQTBCO0FBQzlCOztBQUVBOztJQUVJLDZCQUE2QjtJQUM3Qiw4QkFBOEI7QUFDbEM7O0FBRUE7SUFDSSwwQkFBMEI7QUFDOUI7O0FBRUE7SUFDSSwwQkFBMEI7QUFDOUI7O0FBRUE7O0lBRUksNkJBQTZCO0lBQzdCLDhCQUE4QjtBQUNsQyIsImZpbGUiOiJzcmMvZmVhdHVyZS9ldmVudHMvY29tcG9uZW50cy9ldmVudC10aWNrZXRzLXNvbGQvdGFibGVzL3N0eWxlLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5nZW5lcmFsLWFkbWlzc2lvbi10YWJsZSB0aGVhZCB0cntcbiAgICBwYWRkaW5nOiAwIDMwcHggIWltcG9ydGFudDtcbn1cblxuLmdlbmVyYWwtYWRtaXNzaW9uLXRhYmxlIHRib2R5IHRye1xuICAgIHBhZGRpbmc6IDAgMzBweCAhaW1wb3J0YW50O1xufVxuXG4uZ2VuZXJhbC1hZG1pc3Npb24tdGFibGUgdGgsXG4uZ2VuZXJhbC1hZG1pc3Npb24tdGFibGUgdGQge1xuICAgIHBhZGRpbmctbGVmdDogMzBweCAhaW1wb3J0YW50O1xuICAgIHBhZGRpbmctcmlnaHQ6IDMwcHggIWltcG9ydGFudDtcbn1cblxuLnZlbmRvci10YWJsZSB0aGVhZCB0cntcbiAgICBwYWRkaW5nOiAwIDMwcHggIWltcG9ydGFudDtcbn1cblxuLnZlbmRvci10YWJsZSB0Ym9keSB0cntcbiAgICBwYWRkaW5nOiAwIDMwcHggIWltcG9ydGFudDtcbn1cblxuLnZlbmRvci10YWJsZSB0aCxcbi52ZW5kb3ItdGFibGUgdGQge1xuICAgIHBhZGRpbmctbGVmdDogMzBweCAhaW1wb3J0YW50O1xuICAgIHBhZGRpbmctcmlnaHQ6IDMwcHggIWltcG9ydGFudDtcbn0iXX0= */